import React from 'react'
import { Flex } from '@vtex/store-ui'
import ResponsiveImage from 'src/components/ui/ResponsiveImage'

export const FaixaColab = () => {
  return (
    <Flex>
      <ResponsiveImage
        alt="A troca de produtos será feita apenas em casos de defeito. Alguns produtos poderão ter validade de até 4 meses"
        desktop="/assets/faixa-colaborador-desktop.png"
        mobile="/assets/faixa-colaborador-mobile.png"
      />
    </Flex>
  )
}
