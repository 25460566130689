import React from 'react'
import { Flex } from '@vtex/store-ui'
import {
  FacebookFilledIcon,
  InstagramIcon,
  PinterestIcon,
  YoutubeIcon,
} from 'src/components/icons'

import styles from './styles.json'

const Socials = () => {
  return (
    <Flex sx={styles.container}>
      Os melhores conteúdos
      <Flex sx={styles.iconsContainer}>
        <FacebookFilledIcon />
        <InstagramIcon />
        <PinterestIcon />
        <YoutubeIcon />
      </Flex>
    </Flex>
  )
}

export default Socials
