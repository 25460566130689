import React, { useState } from 'react'
import { Box, Flex, Text, Link, Button, SuspenseDevice } from '@vtex/store-ui'
import { ArrowLeft } from 'src/components/icons'
import GiftIcon from 'src/components/icons/GiftIcon'

import styles from './styles.json'

const Budget = () => {
  const [current, setCurrent] = useState<number>(0)

  const budgetData = [
    {
      id: 0,
      title: 'Presentes até 40 reais',
      href: '/ate-40',
    },
    {
      id: 1,
      title: 'Presentes até 70 reais',
      href: '/ate-70',
    },
    {
      id: 2,
      title: 'Presentes até 100 reais',
      href: '/ate-100',
    },
    {
      id: 3,
      title: 'Presentes acima de 100 reais',
      href: '/acima-de-100',
    },
  ]

  const { length } = budgetData

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1)
  }

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1)
  }

  return (
    <Box sx={styles.container}>
      <Text as="h3" sx={styles.budgetTitle}>
        Cabe dentro do seu orçamento
      </Text>

      <SuspenseDevice device="mobile" fallback={null}>
        <Box sx={styles.budgetContent}>
          <Box sx={styles.budgetContent.controls}>
            <Button onClick={prevSlide}>
              <ArrowLeft />
            </Button>
            <Button onClick={nextSlide}>
              <ArrowLeft />
            </Button>
          </Box>
          <Box>
            {budgetData?.map((item, index) => (
              <Link
                key={item.id}
                sx={
                  index === current
                    ? styles.budgetLinkActive
                    : styles.budgetLinkInactive
                }
                href={item.href}
              >
                <GiftIcon />
                <Text sx={styles.budgetText}>{item.title}</Text>
              </Link>
            ))}
          </Box>
        </Box>
        <Flex sx={styles.budgetContent.dots}>
          {budgetData?.map((item, index) => (
            <Box
              key={item.id}
              sx={
                index === current
                  ? styles.budgetContent.dots.itemActive
                  : styles.budgetContent.dots.itemInactive
              }
            />
          ))}
        </Flex>
      </SuspenseDevice>

      <SuspenseDevice device="desktop" fallback={null}>
        <Box sx={styles.budgetContent}>
          {budgetData?.map((item) => (
            <Link key={item.id} sx={styles.budgetLinkActive} href={item.href}>
              <GiftIcon />
              <Text sx={styles.budgetText}>{item.title}</Text>
            </Link>
          ))}
        </Box>
      </SuspenseDevice>
    </Box>
  )
}

export default Budget
