import React from 'react'
import type { FC } from 'react'
import { useBreadcrumb } from '@vtex/gatsby-theme-store/src/sdk/seo/search/useBreadcrumbJsonLd'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import { useMetadata } from '@vtex/gatsby-theme-store/src/sdk/seo/search/useMetadata'
import type { ServerLandingPageIndexQueryQuery } from 'src/pages/{CmsPlLandingPage.parameters__plLandingPageParams__seo__slug}/__generated__/ServerLandingPageIndexQuery.graphql'
import useRepresentative from 'src/contexts/RepresentativeContext/useRepresentative'

interface Props {
  data: ServerLandingPageIndexQueryQuery
  location: Location
}

const Seo: FC<Props> = ({ data, location }) => {
  const pageBreadcrumb: any = []
  const { seo } = data.cmsPlLandingPage?.parameters?.plLandingPageParams ?? {}

  const metadata = useMetadata({
    title: seo?.title ?? 'Avon',
    description: seo?.description ?? 'Avon',
  })

  const breadcrumb = useBreadcrumb(pageBreadcrumb)
  const { isRepresentativeSelected } = useRepresentative()

  const noIndex =
    isRepresentativeSelected || location?.pathname.includes('teste-colecao')

  return (
    <>
      <GatsbySeo {...metadata} noindex={noIndex} defer />
      {breadcrumb && <BreadcrumbJsonLd {...breadcrumb} defer />}
    </>
  )
}

export default Seo
