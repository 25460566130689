import React, { useEffect } from 'react'
import type { FC } from 'react'
import type { PageProps as GatsbyPageProps } from 'gatsby'
import type { SearchParamsState } from '@vtex/store-sdk'
import { gql } from '@vtex/gatsby-plugin-graphql'
import { navigate, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import {
  HybridWrapper,
  SearchProvider,
  useQuery,
  useSearchParamsFromUrl,
} from '@vtex/gatsby-theme-store'
import Layout from 'src/components/Layout'
import AboveTheFoldPreview from 'src/views/productListLandingPage/components/AboveTheFoldPreview'
import useStoreType from 'src/contexts/StoreTypeContext/useStoreType'
import EventPageView from 'src/components/common/EventPageView'
import { Box, Container, Flex, Text } from '@vtex/store-ui'
import { FaixaColab } from 'src/components/common/FaixaColab'
import RichText from 'src/components/ui/RichText'
import FacetedProductList from 'src/components/common/FacetedProductList'
import Seo from 'src/views/productListLandingPage/Seo'
import CommonQuestions from 'src/components/common/CommonQuestions'
import Socials from 'src/components/product/Socials'
import Budget from 'src/components/kits-e-presentes/Budget'
import ResponsiveImage from 'src/components/ui/ResponsiveImage'

import { useQueryVariablesFromSearchParams } from '../../sdk/search/useQueryVaraiblesFromSearchParams'
import type {
  ServerLandingPageIndexQueryQuery,
  ServerLandingPageIndexQueryQueryVariables,
} from './__generated__/ServerLandingPageIndexQuery.graphql'
import type {
  BrowserIndexLandingPageQueryQuery,
  BrowserIndexLandingPageQueryQueryVariables,
} from './__generated__/BrowserIndexLandingPageQuery.graphql'
import { BrowserIndexLandingPageQuery } from './__generated__/BrowserIndexLandingPageQuery.graphql'

const pageInfo = { size: Number(process.env.GATSBY_STORE_PLP_ITEMS_PER_PAGE) }

const SearchPage: FC<PageProps> = (props) => {
  const { location, data: serverData } = props
  const { isCollaboratorStore } = useStoreType()

  useEffect(() => {
    if (
      !isCollaboratorStore &&
      props.location.pathname.includes('/outletcolaborador')
    ) {
      navigate('/outlet')
    }
  }, [isCollaboratorStore, props.location.pathname])

  const qs = new URLSearchParams(location?.search || '')
  const map = qs.get('map')
  const { clusterId } =
    serverData.cmsPlLandingPage?.parameters?.plLandingPageParams ?? {}

  const slug =
    serverData.cmsPlLandingPage?.parameters?.plLandingPageParams?.seo?.slug ??
    '/s/'

  if (!map) {
    qs.append('map', 'sort')

    location.href = `${location.origin}${
      location.pathname
    }/score-desc?${qs.toString()}`
    location.search = qs.toString()
  }

  const searchParams = useSearchParamsFromUrl(location)

  searchParams.selectedFacets.forEach((selectedFacet, idx) => {
    if (selectedFacet.key === 'priceRange') {
      searchParams.selectedFacets[idx].value = selectedFacet.value
        .replace(/%20|-/g, ' ')
        .replace('to', 'TO')
    }
  })

  const variables = useQueryVariablesFromSearchParams(
    searchParams,
    pageInfo,
    clusterId,
    slug
  )

  searchParams.selectedFacets =
    variables.selectedFacets as SearchParamsState['selectedFacets']

  const { data: browserData } = useQuery<
    BrowserIndexLandingPageQueryQuery,
    BrowserIndexLandingPageQueryQueryVariables
  >({
    ...BrowserIndexLandingPageQuery,
    suspense: true,
    variables,
  })

  if (browserData == null) {
    throw new Error('Something went wrong while fetching the data')
  }

  const totalCount = browserData.vtex?.productSearch?.recordsFiltered ?? 0

  const data = { ...browserData, ...serverData }

  return (
    <SearchProvider
      searchParams={searchParams}
      data={data}
      pageInfo={{
        size: pageInfo.size,
        total: Math.ceil(totalCount / pageInfo.size),
      }}
    >
      <Seo data={data} location={location} />
      <Container sx={{ maxWidth: '1440px' }}>
        <Flex>
          <FacetedProductList data={data} />
        </Flex>
      </Container>
    </SearchProvider>
  )
}

type PageProps = GatsbyPageProps<
  ServerLandingPageIndexQueryQuery,
  ServerLandingPageIndexQueryQueryVariables & { slug: string }
>

const Page: FC<PageProps> = (props) => {
  const { data, location } = props
  const canonicalMetatag = `https://www.avon.com.br/${location.pathname}`
    .replace('.br//', '.br/')
    .replace('.br///', '.br/')
    .replace(/\/$/, '')

  const bannerSection = data?.cmsPlLandingPage?.sections[0]?.props

  const isOutletColaboradorPage =
    data.cmsPlLandingPage?.parameters?.plLandingPageParams?.seo?.slug?.includes(
      'outletcolaborador'
    )

  const hasRichText = data.cmsPlLandingPage?.sections.filter((section) => {
    if (section.name === 'RichText') {
      return section
    }

    return undefined
  })

  const checkPageUrlName = (url: string) => {
    return (
      data.cmsPlLandingPage?.parameters?.plLandingPageParams?.seo?.slug === url
    )
  }

  const isCarnavalPage = checkPageUrlName('/carnaval-avon')

  const isBlackFridayPage = checkPageUrlName('/black-friday-avon')

  const isKitsePresentesPage = checkPageUrlName('/kits-e-presentes')

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalMetatag} />
        {}
      </Helmet>

      <EventPageView
        type="CATEGORY"
        title={
          data.cmsPlLandingPage?.parameters?.plLandingPageParams?.seo?.title
        }
      />

      <Layout>
        {bannerSection && (
          <ResponsiveImage
            alt={bannerSection.alt}
            desktop={bannerSection.desktop.srcSet}
            mobile={bannerSection.mobile.srcSet}
            title={bannerSection.title}
          />
        )}

        {isKitsePresentesPage && (
          <>
            <Budget />
            <Box
              sx={{
                maxWidth: '1440px',
                margin: '0 auto',
                fontFamily: 'Zona Pro, sans-serif',
                textAlign: 'center',
                padding: '1.75rem 0',
              }}
            >
              <Text
                sx={{ fontSize: ['1.25rem', '1.75rem'], fontWeight: 700 }}
                as="h3"
              >
                Diversos presentes para quem você ama
              </Text>
            </Box>
          </>
        )}

        {isOutletColaboradorPage && <FaixaColab />}

        {isCarnavalPage && hasRichText && (
          <Flex
            sx={{
              width: '95vw',
              margin: '0 auto',
              fontFamily: 'Zona Pro, sans-serif',
            }}
          >
            <RichText richText={hasRichText[0].props.content} />
          </Flex>
        )}

        <HybridWrapper fallback={<AboveTheFoldPreview />}>
          <SearchPage {...props} />
        </HybridWrapper>

        {isCarnavalPage && hasRichText && (
          <Flex
            sx={{
              width: '95vw',
              margin: '0 auto',
              fontFamily: 'Zona Pro, sans-serif',
            }}
          >
            <RichText richText={hasRichText[1].props.content} />
          </Flex>
        )}

        <Container sx={{ maxWidth: '1440px', marginTop: '1px' }}>
          {isBlackFridayPage && <CommonQuestions />}
          <Socials />
        </Container>
      </Layout>
    </>
  )
}

/**
 * This query run on the browser
 * */
export const query = gql`
  query BrowserIndexLandingPageQuery(
    $selectedFacets: [VTEX_SelectedFacetInput!]!
    $sort: String!
  ) {
    vtex {
      productSearch(
        from: 0
        to: 11
        orderBy: $sort
        selectedFacets: $selectedFacets
        hideUnavailableItems: false
        simulationBehavior: skip
      ) {
        products {
          ...ProductSummary_product
        }
        recordsFiltered
      }
      facets(
        selectedFacets: $selectedFacets
        operator: or
        behavior: "Static"
        removeHiddenFacets: true
      ) {
        breadcrumb {
          href
          name
        }
        facets {
          name
          type
          values {
            key
            name
            value
            selected
            quantity
            range {
              from
              to
            }
          }
        }
      }
    }
  }
`

/**
 * This query run during SSG
 * */
export const serverSideQuery = graphql`
  query ServerLandingPageIndexQuery($id: String!) {
    cmsPlLandingPage(id: { eq: $id }) {
      name
      parameters {
        plLandingPageParams {
          seo {
            title
            slug
            description
          }
          clusterId
        }
      }
      sections {
        name
        props: data
      }
    }
  }
`

export default Page
