import { styled } from 'src/gatsby-theme-stitches/config'

export const ResponsiveImageContainer = styled('picture', {
  display: 'flex',
  width: '100%',

  img: {
    maxWidth: '100vw',
    width: '100%',
  },
})
