import React from 'react'

import { ResponsiveImageContainer } from './styled'

interface Props {
  desktop: string
  mobile: string
  alt: string
  title?: string
  lazy?: boolean
  width?: string
  height?: string
}

const ResponsiveImage = ({
  alt,
  desktop,
  mobile,
  title,
  lazy,
  height,
  width,
}: Props) => (
  <ResponsiveImageContainer>
    <source media="(max-width: 767px)" srcSet={mobile} />
    <source media="(min-width: 768px)" srcSet={desktop} />
    <img
      alt={alt}
      src={desktop}
      loading={lazy ? 'lazy' : 'eager'}
      title={title ?? alt}
      {...(!!width && { width })}
      {...(!!height && { height })}
    />
  </ResponsiveImageContainer>
)

export default ResponsiveImage
