import React from 'react'
import type { FC } from 'react'

const GiftIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="58.6"
    height="52.089"
    viewBox="0 0 58.6 52.089"
  >
    <g id="noun_Gift_591437" transform="translate(-5 -962.362)">
      <path
        id="Caminho_155"
        data-name="Caminho 155"
        d="M21.929,10a8.492,8.492,0,0,0-8.464,8.464,8.634,8.634,0,0,0,1.343,4.558H6.953a1.964,1.964,0,0,0-.2,0A2.023,2.023,0,0,0,5,24.976v9.116a2.03,2.03,0,0,0,1.953,1.953h1.3V60.135a2.03,2.03,0,0,0,1.953,1.953H58.391a2.03,2.03,0,0,0,1.953-1.953V36.044h1.3A2.03,2.03,0,0,0,63.6,34.091V24.976a2.03,2.03,0,0,0-1.953-1.953H53.793a8.634,8.634,0,0,0,1.343-4.558A8.492,8.492,0,0,0,46.671,10c-3.806,0-6.563,2.593-8.627,5.412-1.4,1.913-2.6,4.026-3.744,5.921-1.145-1.9-2.343-4.008-3.744-5.921C28.492,12.593,25.735,10,21.929,10Zm0,3.907c2.026,0,3.695,1.376,5.473,3.8,1.127,1.54,2.245,3.463,3.357,5.311H21.746a4.6,4.6,0,0,1-4.375-4.558A4.519,4.519,0,0,1,21.929,13.907Zm24.742,0a4.519,4.519,0,0,1,4.558,4.558,4.6,4.6,0,0,1-4.375,4.558H37.84c1.112-1.848,2.23-3.771,3.357-5.311C42.976,15.282,44.645,13.907,46.671,13.907ZM8.907,26.929H27.789v5.209H8.907Zm22.789,0H36.9V58.182H31.7Zm9.116,0H59.693v5.209H40.811ZM12.162,36.044H27.789V58.182H12.162Zm28.649,0H56.438V58.182H40.811Z"
        transform="translate(0 952.362)"
        fill="#fff"
      />
    </g>
  </svg>
)

export default GiftIcon
